import { Grid, Tabs, Tab } from '@mui/material';
import Skills from './Skills';
import { useEffect, useState } from 'react';
import ProfileDetails from './ProfileDetail';
import Attachments from './Attachments';
import { useDispatch } from 'react-redux';
import { getSkillsFramework, getSkillsAnswers } from '../../../redux/actions/skillsAction';
enum TabValue {
    PERSONAL = 'personal',
    SKILLS = 'skills',
    ATTACHMENTS = 'attachments',
}

const Profile = () => {
    const dispatch = useDispatch();
    const [tabValue, setTabValue] = useState(TabValue.PERSONAL);

    useEffect(() => {
        dispatch(getSkillsFramework());
        dispatch(getSkillsAnswers());
    }, [dispatch]);

    const handleChangeTab = (_: React.SyntheticEvent, newValue: TabValue) => {
        setTabValue(newValue);
    };
    return (
        <Grid padding={2}>
            <Grid ml={3} mr={3} mb={3}>
                <Tabs value={tabValue} onChange={handleChangeTab} centered>
                    <Tab label="Personal Details" value={TabValue.PERSONAL} />
                    <Tab label="Skills & Experience" value={TabValue.SKILLS} />
                    <Tab label="Attachments" value={TabValue.ATTACHMENTS} />
                </Tabs>
            </Grid>
            {tabValue === TabValue.PERSONAL && <ProfileDetails />}
            {tabValue === TabValue.SKILLS && <Skills />}
            {tabValue === TabValue.ATTACHMENTS && <Attachments />}
        </Grid>
    );
};

export default Profile;
