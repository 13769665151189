import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    FormControl,
    FormLabel,
    Select,
    MenuItem,
    RadioGroup,
    FormControlLabel,
    Radio,
    TextField,
    DialogActions,
    Grid,
    Divider,
    Typography,
    IconButton,
    Box,
    Autocomplete,
    ToggleButtonGroup,
    InputLabel,
    FormHelperText,
    Chip,
    Switch,
} from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon, Close as CloseIcon, Info as InfoIcon } from '@mui/icons-material';
import { DataGrid, GridRenderCellParams } from '@mui/x-data-grid';
import CommonModal from '../../../components/CommonModal';
import { LookupType, Skill } from '../../../types/skill';
import { selectProfileWizardCompleted } from '../../../redux/selectors/profileSelector';
import { selectSkillsData, selectSkillsAnswers } from '../../../redux/selectors/skillsSelector';
import { setActiveStep } from '../../../redux/actions/profileAction';
import { showSuccessSnackbar, showWarningSnackbar } from '../../../redux/actions/snackbarAction';
import { getSkillsFramework, getSkillsAnswers, upsertSkillsAnswers } from '../../../redux/actions/skillsAction';
import { PROFILE_STEPS } from '../../../data/profileStepperData';
import { matchSorter } from 'match-sorter';

declare interface MappedSkill {
    id: number;
    uuid: string;
    skillset: string;
    reference: string;
    qualification: string;
    experience: string;
    summary: string;
}

interface AutocompleteType {
    id: string;
    origId?: string;
    title?: string;
    level?: number;
    keywords?: any;
    lastChainElement: string;
}

const pages = PROFILE_STEPS.map((step) => step.page);
const activeStep = pages.indexOf('/skills') + 1;
const nextPage = pages[activeStep];
const previousPage = pages[activeStep - 2];

export const CustomButtonGroup = styled(ToggleButtonGroup)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 28rem;
`;

export const SkillsModalContent = () => {
    return (
        <>
            <Typography variant="body2">
                The step requires you to score your most important skills and experience. This will allow employers to quickly and accurately rank you in
                candidate searches that mix and match combinations of skills and experience to suit their role.
            </Typography>
            <br />
            <Typography variant="body2">Some important concepts to understand:</Typography>
            <br />
            <Typography component="ul" variant="body2">
                <li>{`It is important to "score" yourself against at least one skill to be considered within candidate searches.`}</li>
                <li>
                    Employers will compare candidates on best fit against a combination of multiple skills. To improve your ranking in searches it is
                    recommended you score as many skills as you feel comfortable with.
                </li>
                <li>
                    {` The lowest score for any skill is "Aspirant - Not yet skilled". Use this option to be found for upskilling opportunities in areas
            you would like to move into.`}
                </li>
                <li>
                    Skills & experiences can be found within families or by keyword. If you know or can guess the skill name, it is fastest to use the keyword
                    option.
                </li>
                <li>The hierarchical option (ie. skills families) will help you find clusters of relevant skills you should score.</li>
                <li>{`"Experiences" include equipment, organisations and locations you are familiar with.`}</li>
                <li>
                    Adding a sentence or two to explain the background and context for each skill gives employers confidence in understanding your strengths.
                </li>
            </Typography>
        </>
    );
};

const Skills = () => {
    const dispatch = useDispatch();
    const skills = useSelector(selectSkillsData) ?? [];
    const answers = useSelector(selectSkillsAnswers) ?? [];
    const wizardCompleted = useSelector(selectProfileWizardCompleted);

    const [open, setOpen] = useState<boolean>(wizardCompleted ? false : true);
    const [selected, setSelected] = useState(['', '', '']);
    const [qualification, setQualification] = useState('');
    const [experience, setExperience] = useState('');
    const [summary, setSummary] = useState('');
    const [deleteSkill, setDeleteSkill] = useState<any>({});
    const [selectedSkill, setSelectedSkill] = useState<Skill>();
    const [addEditModalOpen, setAddEditModalOpen] = useState(false);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [selectedAutoCompleteItem] = useState<AutocompleteType>();
    const [lookupMethod, setLookupMethod] = useState<LookupType>('KEYWORD' as LookupType);
    const [showErrorMessage] = useState<boolean>(false);
    const [searchString, setSearchString] = useState<string>('');

    const [autoCompleteSkills, setAutoCompleteSkills] = useState<AutocompleteType[]>([]);
    const [autoCompleteLoading, setAutoCompleteLoading] = useState<boolean>(false);

    const filterOptions = (options: AutocompleteType[], { inputValue }: { inputValue: string }) => {
        setAutoCompleteLoading(true);
        if (inputValue === '') {
            return [];
        }
        if (inputValue.length <= 2) {
            return [];
        }
        setAutoCompleteLoading(false);
        return matchSorter(options, inputValue, {
            keys: ['id', 'display', 'keywords'],
            threshold: matchSorter.rankings.CONTAINS,
        }) as AutocompleteType[];
    };

    const navigate = useNavigate();

    useEffect(() => {
        dispatch(getSkillsFramework());
        dispatch(getSkillsAnswers());
    }, [dispatch]);

    function getAutocomplete(source: Skill[]): AutocompleteType[] {
        if (autoCompleteSkills.length > 0) {
            return autoCompleteSkills;
        }
        const computedSkill = source
            .flatMap((skill: any) => {
                const chain = getParents(skill, source) || [];
                const result = chain.map((chain: any, idx: number) => ({
                    chain,
                    id: `${skill.id}-${idx}`,
                    origId: skill.id,
                    qualFramework: skill.qualFramework,
                    expFramework: skill.expFramework,
                    keywords: skill.keywords,
                    display: skill.display,
                }));
                return result;
            })
            .map((flatSkill) => {
                return {
                    ...flatSkill,
                    id: flatSkill.origId,
                    chain: undefined,
                    title: flatSkill.display,
                    lastChainElement: flatSkill.chain[flatSkill.chain.length - 1],
                    level: getLevel(flatSkill.chain),
                    seekerHelpText: getSeekerHelpText(flatSkill.origId, source),
                };
            })
            .filter((s) => s.level === 4);
        const uniqueAC = computedSkill.filter(
            (thing, index, self) =>
                index ===
                self.findIndex(
                    (t) =>
                        //unique on id
                        t.id === thing.id
                )
        );
        const result = uniqueAC.sort(compareTitle);
        setAutoCompleteSkills(result);
        return result;
    }

    function compareTitle(a: any, b: any) {
        if (a.title < b.title) {
            return -1;
        }
        if (a.title > b.title) {
            return 1;
        }
        return 0;
    }

    function getParents(item: Skill, source: Skill[]): any {
        if (item.parents && Array.isArray(item.parents) && item.parents.length > 0) {
            return item.parents
                .filter((p) => p !== item.id)
                .map((p) => {
                    const target = source.find((s) => s.id === p);
                    if (!target) {
                        return [[item.id]];
                    }
                    const parents = getParents(target, source);
                    return parents.map((newP: string[]) => [...newP, item.id]);
                })
                .flat();
        }
        return [[item.id]];
    }

    function getLevel(chain: any) {
        const level = chain?.length;
        return level;
    }

    function getSeekerHelpText(id: string, source: Skill[]) {
        const helpText = source.find((s) => s.id === id)?.seekerHelpText;
        return helpText;
    }

    const handleAutoCompleteChange = (value: AutocompleteType | null) => {
        const skillId = value?.lastChainElement;

        if (!skillId) {
            setSelectedSkill(undefined);
            return;
        }

        const selectedAutocompleteSkill = getAutocomplete(skills).find((skill) => skill.id === skillId);
        if (selectedAutoCompleteItem === undefined) {
            setQualification('');
            setExperience('');
            setSummary('');
        }
        setSelectedSkill(selectedAutocompleteSkill as unknown as Skill);
    };

    function compare(a: any, b: any) {
        if (a < b) {
            return -1;
        }
        if (a > b) {
            return 1;
        }
        return 0;
    }

    const handleClickOpen = () => {
        setSelected(['', '', '']);
        setAddEditModalOpen(true);
    };

    const handleAddEditClose = () => {
        setQualification('');
        setExperience('');
        setSummary('');
        setAddEditModalOpen(false);
        setEditMode(false);
        setSelectedSkill(undefined);
        // selectDropdown(3, '');
    };

    const handleConfirmClose = () => {
        setConfirmModalOpen(false);
    };

    function handleClickRemove(params: any) {
        setDeleteSkill(params.row);
        setConfirmModalOpen(true);
    }

    function handleSkillDelete() {
        dispatch(upsertSkillsAnswers({ [deleteSkill.reference]: null }));
        setConfirmModalOpen(false);
    }

    function handleClickEdit(params: any) {
        setSelectedSkill(skills.find((skill) => skill.id === params.row.reference));
        // // the set* methods don't set the values early enough
        // selectDropdown(3, params.row.reference);
        setQualification(params.row.qualification);
        setExperience(params.row.experience);
        setSummary(params.row.summary);

        setEditMode(true);
        setTimeout(() => setAddEditModalOpen(true), 175);
    }

    const handleLookupChange = (_event: React.MouseEvent<HTMLElement> | null, newLookupMethod: LookupType) => {
        setLookupMethod(newLookupMethod);
        setQualification('');
        setExperience('');
        setSummary('');
        setSelectedSkill(undefined);
        setSelected(['', '', '']);
    };

    function handleSubmit() {
        let refText;
        if (editMode) {
            refText = (selectedSkill as any).id;
        } else {
            if (lookupMethod === 'HIERARCHY') {
                refText = selected[3];
            } else if (lookupMethod === 'KEYWORD') {
                refText = (selectedSkill as any).origId;
            }
        }

        const skillsAnswers = {
            [refText]: {
                qualification,
                experience,
                summary,
            },
        };

        dispatch(upsertSkillsAnswers(skillsAnswers));
        handleAddEditClose();
    }

    function selectDropdown(level: number, value: string) {
        const newArr = [...selected];
        newArr[level] = value;
        for (let i = level + 1; i < 4; i++) {
            newArr[i] = '';
        }
        setQualification('');
        setExperience('');
        setSummary('');
        setSelected(newArr);
        setSelectedSkill(skills.find((skill) => skill.id === newArr[3]));
    }

    const enableSubmitButton = () => {
        if (!selectedSkill) {
            return false;
        }

        let EXISTS;
        if (selectedSkill?.qualFramework && selectedSkill?.expFramework) {
            EXISTS = 'BOTH';
        } else if (selectedSkill?.qualFramework) {
            EXISTS = 'QUAL';
        } else if (selectedSkill?.expFramework) {
            EXISTS = 'EXP';
        } else {
            EXISTS = 'NONE';
        }

        switch (EXISTS) {
            case 'BOTH':
                if (!qualification || !experience) {
                    return false;
                }
                if (qualification?.length > 0 && experience?.length > 0) {
                    return true;
                }
                break;
            case 'QUAL':
                if (qualification?.length > 0) {
                    return true;
                }
                break;
            case 'EXP':
                if (experience?.length > 0) {
                    return true;
                }
                break;
            case 'NONE':
                return true;
            default:
                return false;
        }
    };

    const handleChange = (event: any) => {
        const { name, value } = event.target;

        if (name === 'qualification') {
            setQualification(value);
        }
        if (name === 'experience') {
            setExperience(value);
        }
    };

    function getSkillFromRef(ref: string) {
        const skill = skills.find((skill: Skill) => skill.id === ref) || {};
        return skill;
    }

    const mappedSkills = Object.keys(answers).map((key, idx) => {
        const skill = getSkillFromRef(key) as Skill;
        const answer = answers[key];
        const qualVal = (skill?.qualFramework || {})[answer.qualification || '']?.display || '';
        const expVal = (skill?.expFramework || {})[answer.experience || '']?.display || '';

        return {
            id: idx + 1,
            uuid: skill.uuid,
            skillset: skill.display,
            reference: key,
            qualification: answer.qualification,
            qualDisplay: qualVal,
            experience: answer.experience,
            expDisplay: expVal,
            summary: answer.summary,
        } as MappedSkill;
    });

    const mappedSkillsUuid = mappedSkills.map((mappedSkill) => mappedSkill.uuid);
    const filteredSkills = skills.filter((skill) => !mappedSkillsUuid.includes(skill.uuid || ''));

    const columns = [
        { field: 'skillset', headerName: 'Skills & Experience', flex: 3 },
        { field: 'qualDisplay', headerName: 'Competency / Qualification Level', flex: 3 },
        { field: 'expDisplay', headerName: 'Experience', flex: 1 },
        { field: 'summary', headerName: 'Summary', sortable: false, flex: 4 },
        { field: 'actions', headerName: 'Actions', sortable: false, flex: 1, renderCell: renderActions },
    ];

    function renderActions(params: GridRenderCellParams<any>) {
        return mappedSkills?.length >= 1 ? (
            <>
                <IconButton aria-label="Edit" onClick={() => handleClickEdit(params)}>
                    <EditIcon />
                </IconButton>

                <IconButton aria-label="Delete" onClick={() => handleClickRemove(params)}>
                    <DeleteIcon />
                </IconButton>
            </>
        ) : null;
    }

    const handleToggleModal = () => {
        setOpen(!open);
    };

    const handlePrevious = () => {
        navigate(previousPage);
    };

    const handleNext = () => {
        if (mappedSkills.length === 0) {
            dispatch(showWarningSnackbar('Please select at least one skill, before proceeding'));
            return;
        }
        dispatch(showSuccessSnackbar('Saved successfully!'));
        dispatch(setActiveStep(activeStep));
        navigate(nextPage);
    };

    return (
        <>
            {wizardCompleted && (
                <Typography variant="h4" p={2}>
                    Skills & Experience
                </Typography>
            )}
            <Divider></Divider>

            <Grid sx={{ flexGrow: 1 }} container spacing={2} padding={1}>
                {/* Row 2 */}
                <Grid item xs={12} sx={{ marginLeft: '10px' }}>
                    <Button onClick={handleClickOpen} variant="contained" sx={{ margin: '10px', marginLeft: '0px' }}>
                        Add Skill or Experience
                    </Button>
                    <IconButton aria-label="info" onClick={handleToggleModal} color="primary">
                        <InfoIcon />
                    </IconButton>
                </Grid>
                {/* Row 3 */}
                <Grid item xs={12} sx={{ marginLeft: '10px' }}>
                    {showErrorMessage && <FormHelperText error={true}>Please add at least 1 skill or experience</FormHelperText>}
                    <br />
                    <DataGrid
                        checkboxSelection={false}
                        disableSelectionOnClick
                        rows={mappedSkills}
                        columns={columns}
                        disableExtendRowFullWidth={false}
                        disableColumnFilter
                        disableColumnSelector
                        autoHeight={true}
                        hideFooter
                        rowHeight={38}
                    />
                </Grid>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', marginLeft: '10px' }}>
                    {!wizardCompleted && (
                        <>
                            <Button onClick={handlePrevious} variant="outlined">
                                Previous
                            </Button>

                            <Button onClick={handleNext} variant="contained">
                                Next
                            </Button>
                        </>
                    )}
                </Grid>
            </Grid>

            <Dialog open={addEditModalOpen} onClose={handleAddEditClose} fullWidth maxWidth={'md'}>
                {editMode ? (
                    <DialogTitle sx={{ textTransform: 'capitalize', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        Edit Skillset <br />
                        <Typography component="span" variant="h6" sx={{ fontStyle: 'italic', textTransform: 'capitalize' }}>
                            {selectedSkill?.display?.toLowerCase()}
                        </Typography>
                    </DialogTitle>
                ) : (
                    <DialogTitle sx={{ textTransform: 'capitalize', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        Add a new skill or experience to my profile
                    </DialogTitle>
                )}

                <DialogContent style={{ minHeight: '220px' }}>
                    <Grid sx={{ flexGrow: 1 }} container spacing={2} alignItems="center">
                        {!editMode && (
                            <>
                                <Grid item xs={12} p={0} style={{ display: 'grid', placeItems: 'center' }}>
                                    <Typography variant="body1">Two options for finding and adding skills and experiences</Typography>
                                    <div>
                                        {/* toggle  */}
                                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                            <FormControlLabel
                                                value="Keyword Search"
                                                control={
                                                    <Switch
                                                        checked={lookupMethod === 'HIERARCHY'}
                                                        onChange={(e) => {
                                                            if (e.target.checked) {
                                                                handleLookupChange(null, 'HIERARCHY');
                                                            } else {
                                                                handleLookupChange(null, 'KEYWORD');
                                                            }
                                                        }}
                                                        color="default"
                                                    />
                                                }
                                                label={<Typography variant="h6">Keyword Search</Typography>}
                                                labelPlacement="start"
                                            />
                                            <p className="ml-1">
                                                <Typography variant="h6" className="ml-1">
                                                    Skills Families
                                                </Typography>
                                            </p>
                                        </div>
                                    </div>
                                    <br />
                                </Grid>
                                {/* <Grid item xs={12} p={0}>
                                    <Divider></Divider>
                                </Grid> */}
                            </>
                        )}

                        {!editMode && lookupMethod === 'HIERARCHY' && (
                            <>
                                <Grid item xs={3}>
                                    <FormControl fullWidth sx={{ m: 0, minWidth: 120, maxWidth: 300 }}>
                                        <InputLabel variant="outlined" htmlFor="select-industry">
                                            Level 1
                                        </InputLabel>
                                        <Select
                                            label="Industry"
                                            name="industry"
                                            onChange={(event) => selectDropdown(0, event.target.value)}
                                            value={selected[0]}
                                            inputProps={{ id: 'select-industry' }}
                                        >
                                            {skills
                                                .filter((skill) => (skill.parents || []).length === 0)
                                                .map((record, idx) => (
                                                    <MenuItem key={idx} value={record.id}>
                                                        {record.display}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    {selected[0] && (
                                        <FormControl fullWidth sx={{ m: 0, minWidth: 120, maxWidth: 300 }}>
                                            <InputLabel variant="outlined" htmlFor="select-category">
                                                Level 2
                                            </InputLabel>
                                            <Select
                                                fullWidth
                                                label="Category"
                                                name="category"
                                                onChange={(event) => selectDropdown(1, event.target.value)}
                                                value={selected[1]}
                                                inputProps={{ id: 'select-category' }}
                                            >
                                                {skills
                                                    .filter((skill) => skill.parents?.includes(selected[0]))
                                                    .map((record, idx) => (
                                                        <MenuItem key={idx} value={record.id}>
                                                            {record.display}
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                        </FormControl>
                                    )}
                                </Grid>
                                <Grid item xs={3}>
                                    {selected[1] && (
                                        <FormControl fullWidth sx={{ m: 0, minWidth: 120, maxWidth: 300 }}>
                                            <InputLabel variant="outlined" htmlFor="select-subcategory">
                                                Level 3
                                            </InputLabel>
                                            <Select
                                                fullWidth
                                                label="Sub-category"
                                                name="subcategory"
                                                onChange={(event) => selectDropdown(2, event.target.value)}
                                                value={selected[2]}
                                                inputProps={{ id: 'select-subcategory' }}
                                            >
                                                {skills
                                                    .filter((skill) => skill.parents?.includes(selected[1]))
                                                    .map((record, idx) => (
                                                        <MenuItem key={idx} value={record.id}>
                                                            {record.display}
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                        </FormControl>
                                    )}
                                </Grid>
                                <Grid item xs={3}>
                                    {selected[2] && (
                                        <FormControl fullWidth sx={{ m: 0, minWidth: 120, maxWidth: 300 }}>
                                            <InputLabel variant="outlined" htmlFor="select-skillset">
                                                SKILL or EXPERIENCE
                                            </InputLabel>
                                            <Select
                                                fullWidth
                                                label="SKILL or EXPERIENCE"
                                                name="skillset"
                                                onChange={(event) => selectDropdown(3, event.target.value)}
                                                value={selected[3]}
                                                inputProps={{ id: 'select-skillset' }}
                                            >
                                                {filteredSkills
                                                    .filter((skill) => skill.parents?.includes(selected[2]))
                                                    .map((record, idx) => (
                                                        <MenuItem key={idx} value={record.id}>
                                                            {record.display}
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                        </FormControl>
                                    )}
                                </Grid>
                                <Grid item xs={12} p={0}>
                                    <Divider></Divider>
                                </Grid>
                            </>
                        )}

                        {!editMode && lookupMethod === 'KEYWORD' && (
                            <>
                                <Grid item xs={12}>
                                    <FormControl component="fieldset" fullWidth>
                                        {/* <FormLabel component="legend">Skill or Experience:</FormLabel> */}
                                        <Autocomplete
                                            loadingText={searchString.trim() ? undefined : 'Type a keyword to find associated skills & experiences'}
                                            loading={autoCompleteLoading}
                                            inputValue={searchString}
                                            filterOptions={filterOptions}
                                            onInputChange={(event, value) => {
                                                setSearchString(value);
                                            }}
                                            id="auto-complete"
                                            options={getAutocomplete(filteredSkills)}
                                            getOptionLabel={(option: AutocompleteType) => `${option.title}`}
                                            renderOption={(props, option) => (
                                                <li
                                                    {...props}
                                                    key={option.id}
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        minHeight: 36,
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            flexWrap: 'wrap',
                                                            rowGap: '0.25rem',
                                                            columnGap: '0.25rem',
                                                        }}
                                                    >
                                                        <Typography style={{ fontSize: '1rem', marginRight: 16 }}>{option.title}</Typography>
                                                        {option.keywords.map((item: any, i: number) => (
                                                            <Chip
                                                                key={i}
                                                                label={`${item}`}
                                                                style={{
                                                                    padding: 0,
                                                                    height: '1.5rem',
                                                                    pointerEvents: 'none',
                                                                }}
                                                            />
                                                        ))}
                                                    </div>
                                                </li>
                                            )}
                                            autoComplete={true}
                                            includeInputInList
                                            onChange={(event, value) => {
                                                handleAutoCompleteChange(value);
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    // label='Search Criteria'
                                                    variant="filled"
                                                    sx={{ m: 0, minWidth: 250 }}
                                                    autoFocus
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                            </>
                        )}
                    </Grid>

                    <Grid sx={{ flexGrow: 1 }} container spacing={2} justifyContent="flex-start" alignItems="flex-start">
                        {(selectedSkill || selected[3]) && (
                            <>
                                {selectedSkill?.seekerHelpText && (
                                    <Grid item xs={12}>
                                        <br />
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend">{selectedSkill?.seekerHelpText}</FormLabel>
                                        </FormControl>
                                    </Grid>
                                )}

                                {selectedSkill?.qualFramework && (
                                    <Grid item xs={6}>
                                        <h3>Competency</h3>
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend">What is your highest level</FormLabel>

                                            <RadioGroup name="qualification" onChange={handleChange} value={qualification}>
                                                {Object.keys(selectedSkill?.qualFramework || {})
                                                    .sort(compare)
                                                    .map((key, idx) => (
                                                        <FormControlLabel
                                                            sx={{ mt: 2 }}
                                                            key={idx}
                                                            value={key}
                                                            control={<Radio />}
                                                            label={(selectedSkill?.qualFramework || {})[key].display}
                                                        />
                                                    ))}
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                )}

                                {selectedSkill?.expFramework && (
                                    <Grid item xs={6}>
                                        <h3>Experience</h3>
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend">
                                                How many years cumulative experience do you have studying and/or directly applying this skillset
                                            </FormLabel>
                                            <RadioGroup name="experience" onChange={handleChange} value={experience}>
                                                {Object.keys(selectedSkill?.expFramework || {}).map((key, idx) => (
                                                    <FormControlLabel
                                                        key={idx}
                                                        value={key}
                                                        control={<Radio />}
                                                        label={(selectedSkill?.expFramework || {})[key].display || ''}
                                                    />
                                                ))}
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                )}
                                <Grid item xs={12} p={0}>
                                    <Divider></Divider>
                                </Grid>

                                <Grid item xs={12}>
                                    <h3>Additional details:</h3>
                                    <p>
                                        Provide additional details of qualifications, experience, licences, certifications, professional association memberships
                                        etc. directly related to this skillset. Feel free to add details of personal aspirations and other messages you would
                                        like potential employers to be aware of. Tell your full story. (500 words)
                                    </p>
                                    <TextField
                                        multiline
                                        fullWidth
                                        value={summary}
                                        label="Additional details"
                                        id="fullWidth"
                                        rows={5}
                                        onChange={(event) => setSummary(event.target.value)}
                                    />
                                </Grid>
                            </>
                        )}
                    </Grid>

                    <br />

                    <DialogActions style={{ borderTop: '1px solid rgba(0, 0, 0, 0.12)' }}>
                        <Button onClick={handleAddEditClose} variant="outlined">
                            Cancel
                        </Button>

                        <Button onClick={handleSubmit} disabled={!enableSubmitButton()} variant="contained">
                            {editMode ? 'Save' : 'Add Skills And Experience'}
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>

            <Dialog open={confirmModalOpen} onClose={handleConfirmClose} fullWidth maxWidth="sm">
                <DialogTitle>Confirm delete</DialogTitle>
                <Box position="absolute" top={2} right={2}>
                    <IconButton onClick={handleConfirmClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <DialogContent>
                    <p>
                        Are you sure you want to delete the <b>{deleteSkill.skillset}</b> skillset?
                    </p>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={handleConfirmClose}>
                        Cancel
                    </Button>
                    <Button variant="contained" onClick={handleSkillDelete}>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
            <CommonModal open={open} buttonLabel="I understand" title="TELL US ABOUT YOUR SKILLS, EXPERIENCE & ASPIRATIONS" handleClick={handleToggleModal}>
                <SkillsModalContent />
            </CommonModal>
        </>
    );
};

export default Skills;
